<template lang="pug">
.tariff-column(:class="hit && '_hit'")
  header
    .tariff-column__hit(:class="!hit && '_empty'")
      template(v-if="hit || hitLabel") {{ hitLabel || 'ХИТ' }}
    .tariff-column__title {{ title }}
    .tariff-column__description
      span {{ description }}
      span._info {{ descriptionHint }}
    .tariff-column__cost
      span._small от
      span {{ price }}
      span._small ₽
      old-warning(v-if="showPriceHint" label="Цена может быть увеличена в случае сложных или нестандартных требований к экспертному поиску")
    .promo-description(v-if="actionCost")
      span.description Стоимость по <b>акции</b> для новых пользователей соcтавляет
      span.cost
        span._small от
        span {{ actionCost }}
        span._small ₽
    ui-button(type="secondary" @click="$emit('selectTariff')") Выбрать план
  .tariff-column__content
    .tariff-column__feature-block(
      v-for="feature in features"
      :key="feature.id"
    )
      .tariff-column__feature-block-title {{ feature.title }}
      .tariff-column__feature-item(
        v-for="subFeature in feature.subFeatures"
        :key="subFeature.id"
      )
        div(:class="!includeOptions.includes(subFeature.id) && 'hidden'") {{ subFeature.title }}
        .no-feature(v-if="!includeOptions.includes(subFeature.id)")
  footer
    .tariff-column__hit(:class="!hit && '_empty'")
      template(v-if="hit || hitLabel") {{ hitLabel || 'ХИТ' }}
    .tariff-column__title {{ title }}
    .tariff-column__description._small {{ description }}
    .tariff-column__cost
      span._small от
      span {{ price }}
      span._small ₽
      old-warning(v-if="showPriceHint" label="Цена может быть увеличена в случае сложных или нестандартных требований к экспертному поиску")
    .promo-description(v-if="actionCost")
      span.description Стоимость по <b>акции</b> для новых пользователей соcтавляет
      span.cost
        span._small от
        span {{ actionCost }}
        span._small ₽
    ui-button(type="secondary" @click="$emit('selectTariff')") Выбрать план
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { costTariff } from "@/utils/formatter/costFormatter";

import UiButton from "@/components/ui/button/UiButton.vue";
import OldWarning from "@/components/ui/tooltip/OldWarning.vue";

export default defineComponent({
  name: "TariffColumn",
  components: {
    UiButton,
    OldWarning,
  },
  emits: [
    'selectTariff',
  ],
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    baseCost: {
      type: Number,
      required: true,
    },
    actionCost: {
      type: String,
      default: '',
    },
    discount: {
      type: Number,
      required: true,
    },
    period: {
      type: Number,
      required: true,
    },
    hit: {
      type: Boolean,
      default: false,
    },
    hitLabel: {
      type: String,
      default: '',
    },
    includeOptions: {
      type: Array,
      required: true,
    },
    descriptionHint: {
      type: String,
      required: true,
    },
    showPriceHint: {
      type: Boolean,
      default: false,
    },
    userCount: {
      type: String,
      default: '1 пользователь',
    },
  },
  setup(props) {

    const price = computed(() => costTariff().format( props.period === 1 ? props.baseCost : Math.trunc((props.baseCost * props.discount * props.period)/100)*100))

    const features = [
      {
        id: 1,
        title: "Поиск Закупок",
        subFeatures: [
          { id: 2, title: "Единая база закупок из нескольких тысяч источников по 44-ФЗ, 223-ФЗ, коммерческим площадкам" },
          { id: 3, title: "Возможность ручного поиска закупок" },
          { id: 4, title: "Автоматические шаблоны поиска закупок без ограничений по количеству шаблонов" },
          { id: 5, title: "Профессиональные шаблоны" },
          { id: 6, title: "Рассылка тендеров на e-mail" },
          { id: 7, title: "Экспертное выявление скрытых тендеров (СФО и ДВФО)" },
          { id: 8, title: "Экспертная фильтрация мусорных тендеров (СФО и ДВФО)" },
        ]
      },
      {
        id: 9,
        title: "Управление Тендерами",
        subFeatures: [
          { id: 10, title: props.userCount },
          { id: 11, title: "Управление жизненным циклом заявок и контрактов" },
          { id: 12, title: "Комментарии и тэги к любым закупкам" },
          { id: 13, title: "Выгрузка закупок в Excel" },
          { id: 14, title: "Разграничение доступа" },
          { id: 15, title: "Email-уведомления о регламентных сроках активных закупок" },
        ]
      },
      {
        id: 17,
        title: "Аналитика",
        subFeatures: [
          { id: 18, title: "Аналитика по поставщикам" },
          { id: 19, title: "Аналитика по заказчикам" },
          { id: 20, title: "Подробная аналитика по сводным отраслям, запросам, группам компаний" },
          { id: 21,title: "Выгрузка результатов аналитики в Excel" },
        ]
      },
    ]

    return {
      price,
      features,
    };
  },
})
</script>

<style scoped lang="scss">
$tariff-border: 0.8px solid #42454b33;
$tariff-shadow: 0 11px 30px rgba(153, 159, 172, 0.2);

$cost-size: 30px;
$cost-height: 36px;
$cost-size-small: 20px;

.tariff-column, header, footer {
  display: flex;
  flex-flow: column;
  gap: 16px;

  align-items: center;
  text-align: center;
}

header {
  padding: 32px 20px;
  border-bottom: $tariff-border;
}

footer {
  padding: 32px 20px;
  border-top: $tariff-border;
}

.tariff-column {
  margin: 16px 0;
  padding: 0;
  border: $tariff-border;
  border-radius: 6px;
  background-color: var(--main-color-white);
  flex: 1;
  position: relative;

  &._hit {
    padding: 16px 0;
    margin: 0;
    box-shadow: $tariff-shadow;

    ::v-deep(.ui-button-secondary) {
      color: #ed7a1d;
      border: 1px solid #FFC310;

      &:hover {
        background-color: #fff6ec;
      }
    }
  }

  .tariff-column__hit {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: var(--main-red-color);
    text-transform: uppercase;
    height: 20px;
  }

  .tariff-column__title {
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
  }

  .tariff-column__description {
    display: flex;
    flex-flow: column;
    font-size: 16px;
    line-height: 20px;
    gap: 16px;
    height: 120px;
    font-weight: 500;

    &._small {
      height: 56px;
    }

    span {
      font-weight: 500;

      &._info {
        font-size: 14px;
        line-height: 18px;
        padding: 0 8px;
        color: #909399;
      }
    }
  }

  .tariff-column__cost {
    font-weight: 700;
    font-size: $cost-size;
    line-height: $cost-height;
    display: flex;
    flex-flow: row;
    gap: 8px;
    align-items: baseline;

    span._small {
      font-size: $cost-size-small;
    }
  }
}

.promo-description {
  display: flex;
  flex-flow: column;
  gap: 8px;

  font-size: 14px;
  line-height: 16px;
  padding: 0 8px;
  color: #F56C6C;
  align-items: center;

  .cost {
    font-weight: 700;
    font-size: $cost-size;
    line-height: $cost-height;
    display: flex;
    flex-flow: row;
    gap: 8px;
    align-items: baseline;

    span._small {
      font-size: $cost-size-small;
    }
  }
}

.tariff-column__content  {
  .tariff-column__feature-block-title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    padding: 22px 16px;
  }

  .tariff-column__feature-item {
    font-size: 14px;
    line-height: 18px;
    padding: 8px 16px;
    color: #6e6e6e;
    position: relative;

    .hidden {
      visibility: hidden;
    }

    .no-feature {
      position: absolute;
      width: 10%;
      left: 45%;
      top: 50%;
      border-bottom: 1px solid #6e6e6e;
    }
  }
}

@media (max-width: 1000px) {
  .tariff-column {
    .tariff-column__title {
      font-size: 18px;
    }
    .tariff-column__description {
      font-size: 14px;
      line-height: 18px;
    }
    .tariff-column__cost {
      font-size: 26px;
      &._small {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 860px) {
  .tariff-column {
    .tariff-column__description {
      height: 120px;
    }
  }
}

@media (max-width: 640px) {
  .tariff-column {
    .tariff-column__description {
      height: 160px;
    }
  }
}

@media (max-width: 460px) {
  .tariff-column {
    .tariff-column__hit {
      &._empty {
        display: none;
      }
    }
    .tariff-column__description {
      height: auto;

      &._small {
        height: auto;
      }
    }
  }
}
</style>
