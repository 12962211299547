<template lang="pug">
.tariff-switcher
  .tariff-switcher__elem
    ui-button(:type="selectedPeriod === 1 ? 'primary' : 'secondary'" @click="selectPeriod(1, 1)") 1 месяц
  .tariff-switcher__elem
    ui-button(:type="selectedPeriod === 6 ? 'primary' : 'secondary'" @click="selectPeriod(6, 0.85)") 6 месяцев
    sup.badge - 15%
  .tariff-switcher__elem
    ui-button(:type="selectedPeriod === 12 ? 'primary' : 'secondary'" @click="selectPeriod(12, 0.8)") 12 месяцев
    sup.badge - 20%
</template>

<script lang="ts">
import { ref } from "vue";
import UiButton from "@/components/ui/button/UiButton.vue";

export default {
  name: "TheTariffSwitcher",
  emits: [
    "setDiscount",
  ],
  components: {
    UiButton,
  },
  setup(props: any, context: any) {

    const selectedPeriod = ref(1)

    function selectPeriod(month: number, discount: number) {
      selectedPeriod.value = month
      context.emit('setDiscount', { discount: discount, period: month })
    }

    return {
      selectedPeriod,
      selectPeriod,
    }
  }
}
</script>

<style scoped lang="scss">
.tariff-switcher {
  display: flex;
  gap: 16px;
  flex-flow: row wrap;
  justify-content: center;
  padding: 8px;
  align-items: center;

  ::v-deep(.ui-button) {
    width: 144px;
    justify-content: center;
  }

  .tariff-switcher__elem {
    position: relative;
  }

  .badge {
    right: -16px;
    z-index: 1;
    top: -9px;
    position: absolute;
    font-size: 12px;
    line-height: 14px;
    background-color: #f56c6c;
    color: var(--main-color-white);
    border-radius: 25px;
    padding: 2px 8px;
  }
}
</style>
